import { useState, useEffect } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { config } from "../../utils/config";
import { v4 as uuidv4 } from "uuid";
import aws from "aws-sdk";
import DatePicker from "react-datepicker";
import { Multiselect } from "multiselect-react-dropdown";
import { tokenDecode } from "../../utils/helper";
import "../../index.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import VerifiedIcon from "@mui/icons-material/Verified";
import CircularProgress from "@mui/material/CircularProgress";

const { apiEndpoint, awsKeyId, awsSecretKey, baseNodeapiUrl } = config;
interface ICustomer {
  id: any;
}
export default function UpdateSeller() {
  let params: ICustomer = useParams();
  const { id } = params;
  // console.log(id);
  const decode = tokenDecode();
  const seller_id = decode?.data?.user?.seller_id;
  const rawState = {
    id: 7,
    company_name: "",
    contact_no: "+1",
    street: " ",
    city: " ",
    area_code: " ",
    state: " ",
    personal_address: " ",
    bank_account: " ",
    ifsc_code: " ",
    beneficiary_name: " ",
    approve_status: 0,
    description: " ",
    commission: "",
    email: "",
    name: "",
    fulfillment_option: "",
    business_entity_type: "",
    tax: "",
    taxnumber: "",
    sellerType:"",
    shipping_method: "",
    profile_image: "",
    cardnumber: "",
    varifydoc: "",
    varifydocimage: "",
  };
  const rawErrorData = {
    company_name: false,
    contact_no: false,
    street: false,
    city: false,
    area_code: false,
    state: false,
    personal_address: false,
    bank_account: false,
    ifsc_code: false,
    beneficiary_name: false,
    approve_status: false,
    description: false,
    commission: false,
    email: false,
    name: false,
    fulfillment_option: false,
    business_entity_type: false,
    tax: false,
    taxnumber: false,
    sellerType:false,
    shipping_method: false,
    profile_image: false,
    cardnumber: false,
    varifydoc: false,
    varifydocimage: false,
  };
  const [sellerData, setSellerData] = useState(rawState);
  const [sellerDataError, setSellerDataError]: any = useState(rawErrorData);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const errorHandler = (e: any) => {
    if (e.target.value) {
      setSellerDataError({ ...sellerDataError, [e.target.name]: "valid" });
    } else {
      setSellerDataError({ ...sellerDataError, [e.target.name]: true });
    }
  };

  const fetchSellerData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/seller/getseller`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: id,
        },
      });
      // console.log("update to data", data);

      if (data.status === 200) {
        setSellerData({ ...data.response, id: id });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const inputHandler = (e: any) => {
    if (e.target.name === "contact_no") {
      if (e.target.value === "+1" || e.target.value === "") {
          setSellerData({ ...sellerData, [e.target.name]: "+1" });
      } else if (e.target.value.length === 1 && !isNaN(e.target.value)) {
          setSellerData({ ...sellerData, [e.target.name]: "+1" + e.target.value });
      } else if (e.target.value.length > 1 && !isNaN(e.target.value)) {
          setSellerData({ ...sellerData, [e.target.name]: e.target.value });
      }
    }
    else{
      setSellerData({ ...sellerData, [e.target.name]: e.target.value });
    }
  };

  const newUserCreatehandler = async () => {};
  const ImageHandler = async (e: any) => {
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: awsKeyId,
        secretAccessKey: awsSecretKey,
      });
      const params = {
        Bucket: "savnewstorage",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type,
      };
      let { Location } = await s3.upload(params).promise();
      return Location;
    } catch (exception) {
      console.log(exception);
      return false;
    }
  };
  const uploadImage = async (e: any) => {
    setIsImageUploading(true);
    const image = await ImageHandler(e);
    // console.log(image);
    setSellerData({ ...sellerData, [e.target.name]: image });
    setIsImageUploading(false);
  };

  const [isAnalyzing, setisAnalyzing] = useState(false);
  const [sellerList, setSellerList] = useState([]);

  const preventMinus = (e: any) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const Sstyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 2,
  };
  const steps = ["Low", "Medium", "High"];
  const getSellerList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/seller/getSellerList`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        // console.log(data.data)
        setSellerList(
          data.data.filter((item: any) => item.approve_status === 1)
        );
        let providerId = data.data.filter((item: any) => item.id == id);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month: string | number = today.getMonth() + 1;
    let day: string | number = today.getDate();

    // Add leading zero if month or day is a single digit
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }



  const updateSellerHandlre = async () => {
    // console.log("setSellerData", sellerData);
    const {
      company_name,
      
      contact_no,
      street,
      city,
      area_code,
      state,
      personal_address,
      bank_account,
      ifsc_code,
      beneficiary_name,
      approve_status,
      description,
      commission,

      varifydoc,
      varifydocimage,
      email,
      name,
      fulfillment_option,
      business_entity_type,
      tax,
      taxnumber,
      sellerType,
      shipping_method,

      profile_image,
      cardnumber,
      
    } = sellerData;
    if(sellerData.sellerType==="business" ){
      if (
        name &&
        email &&
        commission &&
        beneficiary_name &&
       ifsc_code &&
       bank_account &&
        personal_address &&
        state &&
        area_code &&
        city &&
        // street &&
        contact_no &&
        company_name &&
        cardnumber &&
        profile_image &&
     

        fulfillment_option &&
        business_entity_type &&
        varifydocimage &&
        varifydoc &&
        tax &&
        taxnumber
      ) {
        const { data } = await axios({
          url: `${apiEndpoint}/seller/sellerupdate`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: sellerData,
        });
        console.log("--- updated data ----");
        if (data.status === 200) {
          toast.success("Seller updated successfully !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("403 error !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("Please fill all the fields !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    else if(sellerData.sellerType==="organization" ){
      if (
        name &&
        email &&
        commission &&
        beneficiary_name &&
       ifsc_code &&
       bank_account &&
        personal_address &&
        state &&
        area_code &&
        city &&
        //street &&
        contact_no &&
        company_name &&
        cardnumber &&
        profile_image &&
     

        fulfillment_option 
        //business_entity_type &&
        //varifydocimage &&
        //varifydoc &&
        //tax &&
        //taxnumber
      ) {
        const { data } = await axios({
          url: `${apiEndpoint}/seller/sellerupdate`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: sellerData,
        });
        console.log("--- updated data ----");
        if (data.status === 200) {
          toast.success("Seller updated successfully !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("403 error !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("Please fill all the fields !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    else{
      if (
        name &&
        email &&
        commission &&
        //beneficiary_name &&
        //ifsc_code &&
       // bank_account &&
        personal_address &&
        state &&
        area_code &&
        city &&
        //street &&
        contact_no &&
        company_name &&
        profile_image &&
    
        fulfillment_option
       
      ) {
        const { data } = await axios({
          url: `${apiEndpoint}/seller/sellerupdate`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: sellerData,
        });
        // console.log("--- updated data ----");
        if (data.status === 200) {
          toast.success("Seller updated successfully !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("403 error !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("Please fill all the fields !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  useEffect(() => {
    fetchSellerData();

    getSellerList();
  }, []);

  const formatContactNumber = (value: any) => {
    if (!value) return "";
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return value;
  };
  const handleContactChange = (e: any) => {
    const inputVal = e.target.value;
    const cleaned = inputVal.replace(/[^\d]/g, "");
    let formattedValue = cleaned;
    if (!cleaned.startsWith("1")) {
      formattedValue = "1" + cleaned;
    }
    formattedValue = formatContactNumber(`+${formattedValue}`);


    setSellerData({ ...sellerData, contact_no: formattedValue });
  };
  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-1">
          <div className="col-md-12 ">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Update seller
                </h6>
              </div>
              <div className="card-body">
                {!isLoading ? (
                  <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">Name</label>
                      <span className="text-danger"> * </span>
                      {sellerDataError?.name !== "valid" &&
                      sellerDataError?.name !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Name"
                        value={sellerData.name}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">Company name</label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.company_name !== "valid" &&
                      sellerDataError?.company_name !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="company_name"
                        placeholder="Company name"
                        value={sellerData.company_name}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">Email address</label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.email !== "valid" &&
                      sellerDataError?.email !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        value={sellerData.email}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">Contact No.</label>
                      <span className="text-danger"> * </span>
                      <span className="text-primary text-xs top-2 left-2">Only USA phone numbers are allowed</span>

                      {sellerDataError?.contact_no !== "valid" &&
                      sellerDataError?.contact_no !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      
                      <input
                        type="text"
                        name="contact_no"
                        className="form-control"
                        placeholder="Contact number"
                        value={sellerData.contact_no}
                        onChange={handleContactChange}
                        onBlur={(e) => errorHandler(e)}
                        maxLength={12}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">
                        Profile image
                        <span className="text-danger"> * </span>

                      {sellerDataError?.profile_image !== "valid" &&
                      sellerDataError?.profile_image !== false ? (
                         <>
                         <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                        
                         
                          </>
                      ):""}
                       <a
                            href={sellerData.profile_image}
                            target="_blank"
                            className="small_text"
                          >
                            {" "}
                            View Document
                          </a>
                          {isImageUploading ? <div className="flex"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4335 4335" width="20" className="loading" style={{ color: "#0ba934e0" }}> <path fill="#008DD2" d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z" /></svg><span style={{ color: "#0ba934e0",fontSize:"12px" }}>Uploading.....</span></div> : null}
                      </label>
                      <input
                        type="file"
                        name="profile_image"
                        className="form-control"
                        onChange={(e) => uploadImage(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  {sellerData?.sellerType=="business"?
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">
                        Credit Card Number
                        <span className="text-danger"> * </span>
                      </label>

                      {sellerDataError?.cardnumber !== "valid" &&
                      sellerDataError?.cardnumber !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="cardnumber"
                        placeholder="Credit Card Number"
                        value={sellerData.cardnumber}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        maxLength={15}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>:""}
                  {sellerData?.sellerType=="business"?
                  <div className="col-md-6">
                    <label className="genericlabel">
                      {" "}
                      Verification Documents{" "}
                      <span className="text-danger">*</span>
                      {sellerDataError?.varifydoc !== "valid" &&
                      sellerDataError?.varifydoc !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <div className="form-group">
                      <select
                        name="varifydoc"
                        id=""
                        className="form-control"
                        value={sellerData.varifydoc}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      >
                        <option value="">
                          Select Documentation verifying the business's legal
                          existence and tax status
                        </option>
                        <option value="business_licenses">
                          Business Licenses
                        </option>
                        <option value="article">
                          Articles of incorporation
                        </option>
                        <option value="tax certificate">
                          Tax registration certificates
                        </option>
                        <option value="utility bills">Utility bills</option>
                      </select>
                    </div>
                  </div>:""}
                  {sellerData?.sellerType=="business"?
                  <div className="col-md-6">
                    <label className="genericlabel">
                      {" "}
                      Document Verification Image
                      <span className="text-danger"> * </span>

                      {sellerDataError?.varifydocimage !== "valid" &&
                      sellerDataError?.varifydocimage !== false ? (
                        <>
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                        
                        
                          </>
                      ):""}
                      <a
                            href={sellerData.varifydocimage}
                            target="_blank"
                            className="small_text"
                          >
                            {" "}
                            View Document
                          </a>
                          {isImageUploading ? <div className="flex"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4335 4335" width="20" className="loading" style={{ color: "#0ba934e0" }}> <path fill="#008DD2" d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z" /></svg><span style={{ color: "#0ba934e0",fontSize:"12px" }}>Uploading.....</span></div> : null}
                    </label>
                    <div className="form-group">
                      <input
                        type="file"
                        name="varifydocimage"
                        className="form-control"
                        onChange={(e) => uploadImage(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>:""}
                  {sellerData?.sellerType=="business"?
                  <div className="col-md-6">
                    <label className="genericlabel">
                      {" "}
                      Tax Information <span className="text-danger">*</span>
                      {sellerDataError?.tax !== "valid" &&
                      sellerDataError?.tax !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <div className="form-group">
                      <select
                        name="tax"
                        id=""
                        className="form-control"
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        value={sellerData.tax}
                        disabled={isImageUploading}
                      >
                        <option value="">Select Tax Information</option>
                        <option value="tin">Tax identification Number</option>
                        <option value="ein">
                          Employer Identification Number
                        </option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>:""}
                  {sellerData?.sellerType=="business"?
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">
                        Tax Information Number
                      </label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.taxnumber !== "valid" &&
                      sellerDataError?.taxnumber !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="taxnumber"
                        placeholder="Tax Information Number"
                        value={sellerData.taxnumber}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>:""}
                  {sellerData?.sellerType=="business"?
                  <div className="col-md-6">
                    <label className="genericlabel">
                      {" "}
                      Business entity type{" "}
                      <span className="text-danger">*</span>
                      {sellerDataError?.business_entity_type !== "valid" &&
                      sellerDataError?.business_entity_type !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <div className="form-group">
                      <select
                        name="business_entity_type"
                        id=""
                        className="form-control"
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        value={sellerData.business_entity_type}
                        disabled={isImageUploading}
                      >
                        <option value="">Select Business Entity</option>
                        <option value="partnership">Partnership</option>
                        <option value="corporation">Corporation</option>
                        <option value="limited">
                          Limited liability company
                        </option>
                      </select>
                    </div>
                  </div>:""}

                 
                  <div className="col-md-6">
                    <label className="genericlabel">
                      {" "}
                      Fulfillment Option <span className="text-danger">*</span>
                      {sellerDataError?.fulfillment_option !== "valid" &&
                      sellerDataError?.fulfillment_option !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <div className="form-group">
                      <select
                        name="fulfillment_option"
                        id=""
                        className="form-control"
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        value={sellerData.fulfillment_option}
                        disabled={isImageUploading}
                      >
                        <option value="">Select Fulfillment options</option>
                        <option value="UPS">UPS</option>
                        <option value="USPS">USPS</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">
                      Commission ( % )
                        {/* <span className="text-danger" > * </span>
                                                {
                                                    sellerData?.commission ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                } */}
                      </label>
                      <input
                        type="text"
                        name="commission"
                        className="form-control"
                        placeholder="Enter Commission"
                        value={sellerData.commission}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={true}
                        readOnly={true}
                      />
                    </div>
                  </div>
               {sellerData.sellerType==="individual"?"":
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="genericlabel">Approve Status</label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.approve_status !== "valid" &&
                      sellerDataError?.approve_status !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <select
                        className="form-control"
                        name="approve_status"
                        id="exampleFormControlSelect1"
                        onChange={(e) =>
                          setSellerData({
                            ...sellerData,
                            [e.target.name]: e.target.value,
                          })
                        }
                        value={sellerData.approve_status}
                        onBlur={(e) => errorHandler(e)}
                      >
                        <option value="0">New Request</option>
                        <option value="1">Active</option>
                        {/* <option value="2">Blocked</option> */}
                      </select>
                    </div>
                  </div>}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="genericlabel">
                        {sellerData.sellerType=="organization"?"Group Information/Bio":
                        "Product Description"}
                      </label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.description !== "valid" &&
                      sellerDataError?.description !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <textarea
                        className="form-control"
                        name="description"
                        placeholder= {sellerData.sellerType=="organization"?"Group Information/Bio":
                          "Product Description"}
                        value={sellerData.description}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        style={{ height: "150px" }}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Country</label>
                      
                      <input
                        type="text"
                        className="form-control"
                        name="country"
                        value="USA"
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="genericlabel">Address</label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.personal_address !== "valid" &&
                      sellerDataError?.personal_address !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="personal_address"
                        placeholder="Address"
                        value={sellerData.personal_address}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">State</label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.state !== "valid" &&
                      sellerDataError?.state !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        id="State"
                        className="form-control"
                        name="state"
                        // placeholder="State"
                        value={sellerData.state}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">City</label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.city !== "valid" &&
                      sellerDataError?.city !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        placeholder="City"
                        value={sellerData.city}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">Area code</label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.area_code !== "valid" &&
                      sellerDataError?.area_code !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="area_code"
                        placeholder="Area code"
                        value={sellerData.area_code}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">Street</label>
                      {/*<span className="text-danger"> * </span>

                      {sellerDataError?.street !== "valid" &&
                      sellerDataError?.street !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}*/}
                      <input
                        type="text"
                        className="form-control"
                        name="street"
                        placeholder="Street"
                        value={sellerData.street}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>

                  <div className=" col-md-12 border my-3"></div>

                  <div className="col-md-12">
                    <p className="font-weight-bold"> Bank details </p>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">
                        Bank account
                       {/* <span className="text-danger"> * </span>*/}
                      </label>

                      {/* {sellerDataError?.bank_account !== "valid" &&
                      sellerDataError?.bank_account !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null} */}
                      <input
                        type="text"
                        className="form-control"
                        name="bank_account"
                        placeholder="Bank account"
                        value={sellerData.bank_account}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">Routing Number </label>
                      {/* <span className="text-danger"> * </span>

                      {sellerDataError?.ifsc_code !== "valid" &&
                      sellerDataError?.ifsc_code !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null} */}
                      <input
                        type="text"
                        className="form-control"
                        name="ifsc_code"
                        placeholder="Enter Routing Number"
                        value={sellerData.ifsc_code}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">Beneficiary name </label>
                      {/* <span className="text-danger"> * </span>

                      {sellerDataError?.beneficiary_name !== "valid" &&
                      sellerDataError?.beneficiary_name !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null} */}
                      <input
                        type="text"
                        className="form-control"
                        name="beneficiary_name"
                        placeholder="Beneficiary name"
                        value={sellerData.beneficiary_name}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>

                  <div className=" col-md-12 border my-3"></div>

                  <div className="col-md-12 mt-4">
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={updateSellerHandlre}
                    >
                      Update
                    </button>
                  </div>
                  {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }} >
                    <CircularProgress />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
}

