import { useState, useEffect } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { v4 as uuidv4 } from "uuid";
import { Multiselect } from "multiselect-react-dropdown";
import Bankdetails from "../SellerRegisteration/BankDetails";
import Personaldetails from "../SellerRegisteration/Personaldetails";
import styles from "./Sellerdetails.module.css";
import styled from "styled-components";
import style from "../SellerRegisteration/seller_register.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { show, hide } from "react-functional-modal";
import { jwtDecode } from "jwt-decode";

const { apiEndpoint } = config;

const s3Config = {
  bucketName: "bucket-name",
  dirName: "directory-name" /* Optional */,
  region: "ap-south-1",
  accessKeyId: "ABCD12EFGH3IJ4KLMNO5",
  secretAccessKey: "a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0",
  s3Url: "https:/your-aws-s3-bucket-url/" /* Optional */,
};
const aws = require("aws-sdk");

const ImageContainer = styled.div`
  border: 1px solid black;
  border-radius: 27px;
  width: 83%;
  background: white;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    width: 100% !important;
    height: 80%;
    object-fit: contain;
  }

  button {
    position: absolute;
    top: 4px;
    right: 11px;
    background: none;
    outline: none;
    border: none;
  }
`;

export default function Sellerdetails() {
  const [showThank, setshowThank] = useState(false);

  let params = useParams();
  const { id } = params;
  let rawToken = localStorage.getItem("raw_token");
  const [pageNamem, setpageName] = useState(true);
  const [loading, setLoading] = useState(false);
  const decode = tokenDecode();

  const [isImageUploading, setIsImageUploading] = useState(false);
  const rawSData = {
    name: "",
    email: "",
    company_name: "",
    contact_no: "+1",
    commission: 7,
    profile_image: "",
    shipping_method: "",
    fulfillment_option: "",
    description: " ",
    cardnumber: "",
    varifydoc: "",
    varifydocimage: "",
    tax: "",
    taxnumber: "",
    business_entity_type: "",
  };
  const rawSErrorData = {
    name: false,
    email: false,
    company_name: false,
    contact_no: false,
    commission: false,
    profile_image: false,
    shipping_method: false,
    fulfillment_option: false,
    description: false,
    cardnumber: false,
    varifydoc: false,
    varifydocimage: false,
    tax: false,
    taxnumber: false,
    business_entity_type: false,
  };
  const decodededTok = tokenDecode();
  const { data } = decodededTok;

  const isApproved = data?.seller_data?.approve_status;
  const [sellerData, setSellerData] = useState(rawSData);
  const [sellerDataError, setSellerDataError] = useState(rawSErrorData);
  useEffect(() => {
    // let rawToken = localStorage.getItem("us_st_d");
    // var decode = jwtDecode(rawToken);
    // let decodedUserdata = decode.data;
    // setSellerData({
    //   ...sellerData,
    //   name: decodedUserdata?.user?.name,
    //   company_name: decodedUserdata.seller_data.company_name,
    //   contact_no: decodedUserdata.seller_data.contact_no,
    //   email: decodedUserdata?.user?.email,
    //   commission: decodedUserdata.seller_data.commission,
    // });
    // getId();
  }, []);

  function tabredirect() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tab = urlParams.get("tab");
    return tab;
  }
  const [renderComponent, setrenderComponent] = useState(tabredirect());


  // const [userData, setUserData] = useState(rawSData);
  // const [userDataError, setUserDataError] = useState(rawSErrorData);



  const open = () => {
    show(
      <ImageContainer>
        <img src={sellerData.profile_image} alt="" />
        <button
          onClick={() => {
            hide("key#1");
          }}
        >
          x
        </button>
      </ImageContainer>,
      { key: "key#1" }
    );
  };

  //code for the days selection end:-

  const inputHandler = (e) => {
    setSellerData({ ...sellerData, [e.target.name]: e.target.value });
  };
  const errorHandler = (e) => {
    if (e.target.value) {
      setSellerDataError({ ...sellerDataError, [e.target.name]: false });
    } else {
      setSellerDataError({ ...sellerDataError, [e.target.name]: true });
    }
  };
  const getRole = async () => {
    let payload;
    if (decode?.data?.user?.seller_id === 0) {
      payload = decode?.data?.user?.parent_id;
    } else {
      payload = decode?.data?.user?.seller_id;
    }
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/seller/sellerShop`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: { seller_id: payload },
      });

      if (data.status === 200) {
        setSellerData(data.response);
        setpageName(false);

      }
    } catch (error) {}
  };
  const submitSellerDetailsHandler = async () => {
    setLoading(true);

    try {
      const {
        name,
        email,
        company_name,
        contact_no,
        commission,
        profile_image,
        shipping_method,
        fulfillment_option,
        description,
      } = sellerData;
      //console.log(userData);
      if (
        name &&
        email &&
        company_name &&
        contact_no !== "" &&
        commission !== "" &&
        profile_image !== "" &&
        fulfillment_option!== "" &&
        description!== ""
        
      ) {
        const { data } = await axios({
          url: `${apiEndpoint}/seller/basic_details_seller`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: { ...sellerData, commission: sellerData.commission },
        });
        // console.log(data.status);
        if (data.status == 200) {
          toast.success("Details submitted Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          //setSellerData(data.response);
          setTimeout(() => {
            pageChangeController();
          }, 3000);
        }
      } else {
        toast.error("Please fill all the fields !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("404 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };




  const inputeHandler = (e) => {
    if (e.target.name === "contact_no") {
      if (e.target.value === "+1" || e.target.value === "") {
          setSellerData({ ...sellerData, [e.target.name]: "+1" });
      } else if (e.target.value.length === 1 && !isNaN(e.target.value)) {
          setSellerData({ ...sellerData, [e.target.name]: "+1" + e.target.value });
      } else if (e.target.value.length > 1 && !isNaN(e.target.value)) {
          setSellerData({ ...sellerData, [e.target.name]: e.target.value });
      }
    }
    else{
      setSellerData({ ...sellerData, [e.target.name]: e.target.value });
    }
  };

  const erroreHandler = (e) => {
    if (e.target.value) {
      setSellerDataError({ ...sellerDataError, [e.target.name]: "valid" });
    } else {
      setSellerDataError({ ...sellerDataError, [e.target.name]: true });
    }
  };

  const productImageHandler = async (e) => {
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: config?.awsKeyId,
        secretAccessKey: config?.awsSecretKey,
      });
      const params = {
        Bucket: "savnewstorage",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type,
      };
      let result = await s3.upload(params).promise();
      const { Location } = result;
      // console.log(Location);

      setSellerData({ ...sellerData, [e.target.name]: Location });
    } catch (exception) {
      // console.log(exception);
    }
  };

  function renderController(e) {
    setrenderComponent(e.target.value);
    // givedta()
  }

  function pageChangeController() {
    if (renderComponent === "shopdetails") {
      setrenderComponent("personaldetails");
    }
    if (renderComponent === "personaldetails") {
      setrenderComponent("bankdetails");
    }
  }

  useEffect(() => {
    getRole();
    if (localStorage.getItem("showthank")) {
      setshowThank(true);
    }
  }, []);
  const formatContactNumber = (value) => {
    if (!value) return "";
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return value;
  };
  const handleContactChange = (e) => {
    const inputVal = e.target.value;
    const cleaned = inputVal.replace(/[^\d]/g, "");
    let formattedValue = cleaned;
    if (!cleaned.startsWith("1")) {
      formattedValue = "1" + cleaned;
    }
    formattedValue = formatContactNumber(`+${formattedValue}`);


    setSellerData({ ...sellerData, contact_no: formattedValue });
  };
  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-2">
          <div className="col-md-12 ">
            <div className="card shadow mb-4">
              <div className="card-header p-0">
                {showThank && (
                  <div
                    className={style.thanks_container}
                    style={{ background: "white" }}
                  >
                    {/* <div className="w-100 d-flex justify-content-center" >
                                        <img src={thanks} alt="" className="w-50" />
                                    </div> */}

                    <div className={style.thanks_statement}>
                      <p>Thank you for registering with Seller Portal</p>
                      <p
                        className="m-1"
                        style={{
                          fontSize: "13px",
                          background: "white",
                          marginBottom: "12px",
                        }}
                      >
                        Your request is under process, your outlet will be live
                        once request is approved.
                      </p>
                      <p
                        className="m-1"
                        style={{
                          fontSize: "13px",
                          background: "white",
                          marginBottom: "12px",
                        }}
                      >
                        In mean time please confirm shop details.
                      </p>
                    </div>
                  </div>
                )}
                <h6 className="m-0 font-weight-bold text-primary">
                  {/* {pageNamem ? "Add Shop Details" : "Update Details"} */}
                  <div className={styles.tabs}>
                    <button
                      onClick={renderController}
                      value="shopdetails"
                      className={
                        renderComponent === "shopdetails"
                          ? `${styles.tab} ${styles.active}`
                          : `${styles.tab}`
                      }
                    >
                      Shop Details
                    </button>
                    <button
                      onClick={renderController}
                      value="personaldetails"
                      className={
                        renderComponent === "personaldetails"
                          ? `${styles.tab} ${styles.active}`
                          : `${styles.tab}`
                      }
                    >
                      Address Details
                    </button>
                    <button
                      onClick={renderController}
                      value="bankdetails"
                      className={
                        renderComponent === "bankdetails"
                          ? `${styles.tab} ${styles.active}`
                          : `${styles.tab}`
                      }
                    >
                      Bank Details
                    </button>
                  </div>
                </h6>
              </div>
              {renderComponent === "shopdetails" ? (
                <>
                {!sellerData.email ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="text-dark"
                            style={{ color: "black", fontWeight: 600 }}
                          >
                            Seller Name
                            <span className="text-danger"> * </span>
                            {sellerDataError?.name !== "valid" &&
                            sellerDataError?.name !== false ? (
                              <span className="text-danger tiny_size">
                                {" "}
                                This field is required !{" "}
                              </span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Name"
                            value={sellerData.name}
                            onChange={(e) => inputeHandler(e)}
                            onBlur={(e) => erroreHandler(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="text-dark"
                            style={{ color: "black", fontWeight: 600 }}
                          >
                            Company name<span className="text-danger">*</span>
                            {sellerDataError?.company_name !== "valid" &&
                            sellerDataError?.company_name !== false ? (
                              <span className="text-danger tiny_size">
                                {" "}
                                This field is required !{" "}
                              </span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="company_name"
                            placeholder="Company name"
                            value={sellerData.company_name}
                            onChange={(e) => inputeHandler(e)}
                            onBlur={(e) => erroreHandler(e)}
                            readOnly={isApproved === 1 ? true : false}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="text-dark"
                            style={{ color: "black", fontWeight: 600 }}
                          >
                            Email address<span className="text-danger">*</span>
                            {sellerDataError?.email !== "valid" &&
                            sellerDataError?.email !== false ? (
                              <span className="text-danger tiny_size">
                                {" "}
                                This field is required !{" "}
                              </span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            value={sellerData.email}
                            onChange={(e) => inputeHandler(e)}
                            onBlur={(e) => erroreHandler(e)}
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="text-dark"
                            style={{ color: "black", fontWeight: 600 }}
                          >
                            Contact No.<span className="text-danger">*</span>
                            {sellerDataError?.contact_no !== "valid" &&
                            sellerDataError?.contact_no !== false ? (
                              <span className="text-danger tiny_size">
                                {" "}
                                This field is required !{" "}
                              </span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            name="contact_no"
                            className="form-control"
                            placeholder="Contact number"
                            value={sellerData.contact_no}
                            onChange={handleContactChange}
                            onBlur={(e) => erroreHandler(e)}
                            maxLength={12}
                          />
                        </div>
                      </div>
                    
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="text-dark"
                            style={{ color: "black", fontWeight: 600 }}
                          >
                            Profile Image
                            <span className="text-danger"> * </span>
                            {sellerDataError?.profile_image ? (
                              <span className="text-danger tiny_size">
                                {" "}
                                This field is required !{" "}
                              </span>
                            ) : null}
                            
                          </label>
                          {sellerData.profile_image && (
                            <a
                              href={sellerData.profile_image}
                              onClick={open}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                fontSize: "10px",
                                marginLeft: "3px",
                                textDecoration: "underline",
                              }}
                            >
                              View Document
                            </a>
                          )}
                          <input
                            type="file"
                            className="form-control"
                            onChange={(e) => productImageHandler(e)}
                            accept="image/*"
                            name="profile_image"
                            multiple
                            onBlur={(e) => errorHandler(e)}
                            id="profile_image"
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label
                            className="text-dark"
                            style={{ color: "black", fontWeight: 600 }}
                          >
                            Commission ( % )
                            <span className="text-danger"> * </span>
                            {sellerDataError?.commission ? (
                              <span className="text-danger tiny_size">
                                {" "}
                                This field is required !{" "}
                              </span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            name="commission"
                            className="form-control"
                            placeholder="Enter Commission"
                            value={sellerData.commission}
                            onChange={(e) => inputHandler(e)}
                            onBlur={(e) => errorHandler(e)}
                            readOnly={isApproved === 1 ? true : true}
                          />
                        </div>
                      </div> */}
                      {sellerData?.sellerType=="business"?
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="genericlabel">
                            Credit Card Number
                            <span className="text-danger"> * </span>
                          </label>
    
                          {sellerDataError?.cardnumber !== "valid" &&
                          sellerDataError?.cardnumber !== false ? (
                            <span className="text-danger tiny_size">
                              {" "}
                              This field is required !{" "}
                            </span>
                          ) : null}
                          <input
                            type="text"
                            className="form-control"
                            name="cardnumber"
                            placeholder="Credit Card Number"
                            value={sellerData.cardnumber}
                            onChange={(e) => inputHandler(e)}
                            onBlur={(e) => errorHandler(e)}
                            maxLength={15}
                            disabled={isImageUploading}
                          />
                        </div>
                      </div>:""}
                      {sellerData?.sellerType=="business"?
                      <div className="col-md-6">
                        <label className="genericlabel">
                          {" "}
                          Verification Documents{" "}
                          <span className="text-danger">*</span>
                          {sellerDataError?.varifydoc !== "valid" &&
                          sellerDataError?.varifydoc !== false ? (
                            <span className="text-danger tiny_size">
                              {" "}
                              This field is required !{" "}
                            </span>
                          ) : null}
                        </label>
                        <div className="form-group">
                          <select
                            name="varifydoc"
                            id=""
                            className="form-control"
                            value={sellerData.varifydoc}
                            onChange={(e) => inputHandler(e)}
                            onBlur={(e) => errorHandler(e)}
                            disabled={isImageUploading}
                          >
                            <option value="">
                              Select Documentation verifying the business's legal
                              existence and tax status
                            </option>
                            <option value="business_licenses">
                              Business Licenses
                            </option>
                            <option value="article">
                              Articles of incorporation
                            </option>
                            <option value="tax certificate">
                              Tax registration certificates
                            </option>
                            <option value="utility bills">Utility bills</option>
                          </select>
                        </div>
                      </div>:""}
                      {sellerData?.sellerType=="business"?
                      <div className="col-md-6">
                        <label className="genericlabel">
                          {" "}
                          Document Verification Image
                          <span className="text-danger"> * </span>
    
                          {sellerDataError?.varifydocimage !== "valid" &&
                          sellerDataError?.varifydocimage !== false ? (
                            <>
                            <span className="text-danger tiny_size">
                              {" "}
                              This field is required !{" "}
                            </span>
                            
                            
                              </>
                          ):""}
                          <a
                                href={sellerData.varifydocimage}
                                target="_blank"
                                className="small_text"
                              >
                                {" "}
                                View Document
                              </a>
                              {isImageUploading ? <div className="flex"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4335 4335" width="20" className="loading" style={{ color: "#0ba934e0" }}> <path fill="#008DD2" d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z" /></svg><span style={{ color: "#0ba934e0",fontSize:"12px" }}>Uploading.....</span></div> : null}
                        </label>
                        <div className="form-group">
                          <input
                            type="file"
                            name="varifydocimage"
                            className="form-control"
                            onChange={(e) => productImageHandler(e)}
                            disabled={isImageUploading}
                          />
                        </div>
                      </div>:""}
                      {sellerData?.sellerType=="business"?
                      <div className="col-md-6">
                        <label className="genericlabel">
                          {" "}
                          Tax Information <span className="text-danger">*</span>
                          {sellerDataError?.tax !== "valid" &&
                          sellerDataError?.tax !== false ? (
                            <span className="text-danger text-[12px]">
                              {" "}
                              This field is required !{" "}
                            </span>
                          ) : null}
                        </label>
                        <div className="form-group">
                          <select
                            name="tax"
                            id=""
                            className="form-control"
                            onChange={(e) => inputHandler(e)}
                            onBlur={(e) => errorHandler(e)}
                            value={sellerData.tax}
                            disabled={isImageUploading}
                          >
                            <option value="">Select Tax Information</option>
                            <option value="tin">Tax identification Number</option>
                            <option value="ein">
                              Employer Identification Number
                            </option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                      </div>:""}
                      {sellerData?.sellerType=="business"?
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="genericlabel">
                            Tax Information Number
                          </label>
                          <span className="text-danger"> * </span>
    
                          {sellerDataError?.taxnumber !== "valid" &&
                          sellerDataError?.taxnumber !== false ? (
                            <span className="text-danger text-[12px]">
                              {" "}
                              This field is required !{" "}
                            </span>
                          ) : null}
                          <input
                            type="text"
                            className="form-control"
                            name="taxnumber"
                            placeholder="Tax Information Number"
                            value={sellerData.taxnumber}
                            onChange={(e) => inputHandler(e)}
                            onBlur={(e) => errorHandler(e)}
                            disabled={isImageUploading}
                          />
                        </div>
                      </div>:""}
                      {sellerData?.sellerType=="business"?
                      <div className="col-md-6">
                        <label className="genericlabel">
                          {" "}
                          Business entity type{" "}
                          <span className="text-danger">*</span>
                          {sellerDataError?.business_entity_type !== "valid" &&
                          sellerDataError?.business_entity_type !== false ? (
                            <span className="text-danger text-[12px]">
                              {" "}
                              This field is required !{" "}
                            </span>
                          ) : null}
                        </label>
                        <div className="form-group">
                          <select
                            name="business_entity_type"
                            id=""
                            className="form-control"
                            onChange={(e) => inputHandler(e)}
                            onBlur={(e) => errorHandler(e)}
                            value={sellerData.business_entity_type}
                            disabled={isImageUploading}
                          >
                            <option value="">Select Business Entity</option>
                            <option value="partnership">Partnership</option>
                            <option value="corporation">Corporation</option>
                            <option value="limited">
                              Limited liability company
                            </option>
                          </select>
                        </div>
                      </div>:""}
                      
                    <div className="col-md-6">
                      <label className="genericlabel">
                        {" "}
                        Fulfillment Option <span className="text-danger">*</span>
                        {sellerDataError?.fulfillment_option !== "valid" &&
                        sellerDataError?.fulfillment_option !== false ? (
                          <span className="text-danger text-[12px]">
                            {" "}
                            This field is required !{" "}
                          </span>
                        ) : null}
                      </label>
                      <div className="form-group">
                        <select
                          name="fulfillment_option"
                          id=""
                          className="form-control"
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          value={sellerData.fulfillment_option}
                          disabled={isImageUploading}
                        >
                          <option value="">Select Fulfillment options</option>
                          <option value="UPS">UPS</option>
                          <option value="USPS">USPS</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                    <div className="form-group">
                      <label className="genericlabel">
                        {sellerData.sellerType=="organization"?"Group Information/Bio":
                          "Product Description"}
                      </label>
                      <span className="text-danger"> * </span>

                      {sellerDataError?.description !== "valid" &&
                      sellerDataError?.description !== false ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <textarea
                        className="form-control"
                        name="description"
                        placeholder= {sellerData.sellerType=="organization"?"Group Information/Bio":
                          "Product Description"}
                        value={sellerData.description}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        style={{ height: "150px" }}
                        disabled={isImageUploading}
                      />
                    </div>
                  </div>
                      <div className="col-md-12">
                        {pageNamem ? (
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={submitSellerDetailsHandler}
                          >
                            {loading ? "Saving..." : "Save"}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={submitSellerDetailsHandler}
                          >
                            {loading ? "Saving..." : "Update"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  )}
                </>
              ) : renderComponent === "personaldetails" ? (
                <Personaldetails pageChangeController={pageChangeController} />
              ) : (
                renderComponent === "bankdetails" && <Bankdetails />
              )}
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
}
